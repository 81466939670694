exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("./../../../src/pages/inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-reviews-js-content-file-path-reviews-amy-friends-vacation-mdx": () => import("./../../../src/templates/reviews.js?__contentFilePath=/codebuild/output/src1480050897/src/perfectlyourstravel/reviews/amyFriendsVacation.mdx" /* webpackChunkName: "component---src-templates-reviews-js-content-file-path-reviews-amy-friends-vacation-mdx" */),
  "component---src-templates-reviews-js-content-file-path-reviews-deena-family-vacation-mdx": () => import("./../../../src/templates/reviews.js?__contentFilePath=/codebuild/output/src1480050897/src/perfectlyourstravel/reviews/DeenaFamilyVacation.mdx" /* webpackChunkName: "component---src-templates-reviews-js-content-file-path-reviews-deena-family-vacation-mdx" */),
  "component---src-templates-reviews-js-content-file-path-reviews-gary-safari-birthday-mdx": () => import("./../../../src/templates/reviews.js?__contentFilePath=/codebuild/output/src1480050897/src/perfectlyourstravel/reviews/GarySafariBirthday.mdx" /* webpackChunkName: "component---src-templates-reviews-js-content-file-path-reviews-gary-safari-birthday-mdx" */),
  "component---src-templates-reviews-js-content-file-path-reviews-jenni-mc-clain-vacation-mdx": () => import("./../../../src/templates/reviews.js?__contentFilePath=/codebuild/output/src1480050897/src/perfectlyourstravel/reviews/jenniMcClainVacation.mdx" /* webpackChunkName: "component---src-templates-reviews-js-content-file-path-reviews-jenni-mc-clain-vacation-mdx" */),
  "component---src-templates-reviews-js-content-file-path-reviews-michelle-crew-vacation-mdx": () => import("./../../../src/templates/reviews.js?__contentFilePath=/codebuild/output/src1480050897/src/perfectlyourstravel/reviews/MichelleCrewVacation.mdx" /* webpackChunkName: "component---src-templates-reviews-js-content-file-path-reviews-michelle-crew-vacation-mdx" */),
  "component---src-templates-reviews-js-content-file-path-reviews-paul-val-honeymoon-mdx": () => import("./../../../src/templates/reviews.js?__contentFilePath=/codebuild/output/src1480050897/src/perfectlyourstravel/reviews/paulValHoneymoon.mdx" /* webpackChunkName: "component---src-templates-reviews-js-content-file-path-reviews-paul-val-honeymoon-mdx" */),
  "component---src-templates-reviews-js-content-file-path-reviews-tonya-bill-anniversary-mdx": () => import("./../../../src/templates/reviews.js?__contentFilePath=/codebuild/output/src1480050897/src/perfectlyourstravel/reviews/TonyaBillAnniversary.mdx" /* webpackChunkName: "component---src-templates-reviews-js-content-file-path-reviews-tonya-bill-anniversary-mdx" */),
  "component---src-templates-reviews-js-content-file-path-reviews-zach-gabrielle-argentina-mdx": () => import("./../../../src/templates/reviews.js?__contentFilePath=/codebuild/output/src1480050897/src/perfectlyourstravel/reviews/ZachGabrielleArgentina.mdx" /* webpackChunkName: "component---src-templates-reviews-js-content-file-path-reviews-zach-gabrielle-argentina-mdx" */)
}

